@import "./icons.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

body {
  * {
    outline: none;
  }
}

body.modal-open {
  padding-right: 0 !important
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

td {
  vertical-align: middle !important;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 25px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

td:last-child {
  vertical-align: middle !important;
  overflow: visible;
  text-overflow: ellipsis;
  line-height: 25px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.leaflet-pane {
  z-index: 1;
}

svg>rect:first-child {
  fill-opacity: 0 !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}

.dz-message {
  text-align: center;
  padding: 30px;
}

// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg  !important;
  margin: 0 20px 0 0 !important;
}

.task-box {
  border: 1px solid $border-color;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

a:hover {
  cursor: pointer;
}

.rating-container {
  background-color: transparent !important;
}

.input-group-append {
  z-index: 0;
}

.input-color {
  color: $input-color  !important;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}

.rangeslider__fill {
  background-color: $primary  !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary  !important;
}

.flatpickr-weekdays {
  background: $primary  !important;
}

span.flatpickr-weekday {
  background: $primary  !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary  !important;
}

span.flatpickr-weekday {
  color: $white  !important;
}

.flatpickr-day.selected {
  background: $primary  !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}

.carousel {
  .control-dots {
    margin: -5px 0px;
  }

  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

//pagination 
.react-bootstrap-table-pagination {
  width: 99.9%;
  margin: auto;
}

.react-bootstrap-table-pagination .dropdown-menu.show {
  top: -145px !important;
}

.table> :not(caption)>*>* {
  background-color: white !important;
}

.table-nowrap td {
  height: auto;
  min-height: 50px;
}

.dropdown-item a {
  color: #495057;
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;

    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }

  .fc {
    .fc-toolbar {
      >* {
        >* {
          float: none;
        }
      }
    }
  }

  .fc-today-button {
    display: none;
  }
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}

.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;

  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}

.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}

.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}

.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.ReactModal__Overlay {
  z-index: 5000 !important;
}

.rangeslider__handle:focus {
  outline: none;
}

// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}

.search-label {
  float: right;
}

.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}

.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}

.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

.btn-custom-width {
  width: 100px
}

.btn-change-password {
  width: 160px
}

.cursor-pointer {
  cursor: pointer;
}

.invalid-inline {
  display: inline-block !important;
}

.text-right {
  text-align: right;
}

.img-avatar {
  border-radius: 50%;
  height: 140px;
  width: 140px;
  max-height: 160px;
  max-width: 160px;
  object-fit: cover!important;
}

.react-bootstrap-table-pagination div {
  padding-left: 0px;
  padding-right: 0px;
}

.content-logo-sidebar {
  text-align: center;
  position: relative;
  display: inline;
  top: 10px;
}

.logo-sidekick-xl {
  border-radius: 15px;
  background-color: #000;
  width: auto;
  width: 100%;
  display: inline-block;
  height: auto;
  max-height: 60px;
  min-width: 30px;
  max-width: 60px;
}

.logo-sidekick {
  border-radius: 15px;
  background-color: #000;
}

.logo-light .logo-sidekick {
  border-radius: 10px;
}

.right-bar-enabled.vertical-collpsed.sidebar-enable .navbar-brand-box {
  top: 10px;
}

.right-bar-enabled.vertical-collpsed.sidebar-enable .navbar-brand-box .logo-sidekick-xl {
  border-radius: 10px;
}

.right-bar-enabled .navbar-brand-box.d-lg-none.d-md-block {
  width: 70px !important;
}
.border-gray-custom {
  border: 1px solid #eff2f7;
}

.active-statistics,
.inactive-statistics,
.total-statistics,
.warning-statistics,
.total-amount-statistics {
  font-size: 30px;
  font-weight: 600;
}

.active-statistics {
  color: rgb(84, 186, 68);
}

.inactive-statistics {
  color: #f46a6a;
}

.warning-statistics {
  color: #ffcd00;
}

.total-amount-statistics {
  color: #5B75FF;
}

.total-statistics {
  color: rgb(34, 124, 202);
}

.export-dropdown-menu:hover {
  color: #000;
}

.height-100 {
  height: 100%;
}

.table-role-dashboard .react-bootstrap-table,
.table-report-dashboard .react-bootstrap-table{
  max-height: 500px;
  overflow-y: auto;
}

.border-card {
  border: 1px solid #dddfe6;
}

.overflow-hidden {
  overflow: hidden;
}

.react-datepicker-popper {
  width: 350px;
}

.ml-10px {
  margin-left: 10px;
}

.video-featured {
  width: auto;
  height: 80px;
}

.video-feature-detail,
.video-feature-form {
  max-width: 100%;
  height: auto;
  max-height: 450px;
}

.video-feature-form {
  max-height: 250px;
  max-width: 250px;
}

.image-expert-featured-list {
  max-width: 100%;
  max-height: 40px;
  border-radius: 5px;
}

.image-expert-featured-detail  {
  max-width: 400px;
  max-height: 400px;
  border-radius: 5px;
}

.image-expert-featured-form {
  height: auto;
  width: auto;
  max-width: 85%;
  max-height: 180px;
}

.upload-file label {
  height: 150px;
}

.image-invalid label {
  border: dashed 2px #f46a6a;
}

.remove-img {
  left: 40px;
  margin-left: 20px;
  margin-top:-20px;
  i {
    font-size: 20px;
    color: #f46a6a;
  }
}

.pb-300 {
  padding-bottom: 300px;
}

.is-invalid {
  .select__control  {
    border-color: #f46a6a;
  }
}

.waitLoadingData {
  pointer-events: none;
  opacity: 0.5;
}

.table-responsive table {
  min-width: 1100px;
  overflow-x: auto;
}

.d-col-filter {
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  flex: 0 0 auto;
  width: 100px;
  padding-right: 0;
}

.dx-row.dx-column-lines.dx-header-row {
  color: black;
  font-weight: 500;
}

.dx-datagrid-rowsview .dx-row {
  border-bottom: 1px solid #eff2f7 !important;
}

.dx-datagrid .dx-column-lines > td,
.dx-datagrid-headers .dx-datagrid-table .dx-row > td,
.dx-datagrid-borders .dx-datagrid-rowsview, .dx-datagrid-headers + .dx-datagrid-rowsview, .dx-datagrid-rowsview.dx-datagrid-after-headers {
  border-color: #eff2f7 !important;;
}

.dx-datagrid-borders > .dx-datagrid-headers, 
.dx-datagrid-borders > .dx-datagrid-rowsview, .dx-datagrid-borders > .dx-datagrid-total-footer {
  border-left: 1px solid #eff2f7 !important;
  border-right: 1px solid #eff2f7 !important;
}

.dx-datagrid-borders > .dx-datagrid-filter-panel, .dx-datagrid-borders > .dx-datagrid-headers {
  border-top: 1px solid #eff2f7 !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-bottom: 1px solid #eff2f7 !important;
}

.dx-row.dx-freespace-row.dx-column-lines {
  display: none;
}
.input-fake {
  z-index: -100;
  height: 1px;
  opacity: 0;
  display: block;
}

.custom-checkbox-input {
  width: 1.3rem !important;
  height: 1.3rem !important;
}
.font-weight-bold {
  font-weight: bold;
}

.required:after {
  content: " *";
  color: #dd4b39;
}

.p-l-r-0 {
  padding-left: 0;
  padding-right: 0;
}

.align-item-center {
  align-items: center;
}

.table-full-width {
  width: 100%;
}

.d-center-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-end-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.table-full-width tr {
  display: flex;
  align-items: center;
}

.max-height-35 {
  max-height: 35px;
  height: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.border-bt-table {
  border-bottom: 1px solid #eff2f7;
}
#sidebar-menu .dropdown-menu.show {
  display: contents;
  ul {
    height: auto !important;
    padding-left: 12px;
  }
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul ul.nav-group-items {
  display: inline;
  span {
    display: none;
  }
  i {
    padding-left: 12px;
  }
}

.card-statistic {
  height: 150px;
  margin-bottom: 10px !important;
  box-shadow: inset 0 0 3px #5B75FF;
}

.title-card {
  height: 75px;
  padding-top: 5px;
  p {
    color: #1ba8f4;
  }
}

.data-card {
  height: 60px;
  color: #5B75FF;
  font-weight: 500;
  span {
    color: #5B75FF;
  }
}

.bg-cornsilk {
  background: cornsilk;
}

.bg-antiquewhite {
  background: antiquewhite;
}

.bg-mintcream {
  background: mintcream;
}

.bg-aliceblue {
  background: aliceblue;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.max-h-350 {
  max-height: 350px;
}

.text-deepskyblue {
  color: deepskyblue;
}

.text-mediumseagreen {
  color: mediumseagreen;
}

.text-orangered {
  color: orangered;
}

.tag-total-profit {
  display: flex;
  right: 0;
  justify-content: end;
  align-items: end;
}

input.c-radio[type=radio] {
  width: 15px;
  height: auto;
}

.label-input-radio {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  margin-bottom: 0 !important;
  margin-left: 5px;
  margin-right: 5px;
}

.bg-show-able {
  background-color: #b2e8ee;
}

@media (min-width: 1200px) {
  .d-col-1-in-7 {
    flex: 0 0 auto;
    width: 14.285%;
  }
}
